import { of } from 'rxjs';
import { tap, switchMap, map, filter } from 'rxjs/operators';

/**
 * Providing two functions to be run before and after the observable emitted.
 * @param {Function} beforeFunc A function to be run before the observable emitted
 * @param {Function} afterFunc A function to be run after the observable emitted
 * @return {Observable<T>} An observable that runs the provided functions before and after the observable emitted
 */
function beforeAfter(beforeFunc, afterFunc) {
  return function (source) {
    return of(null).pipe(tap(() => {
      if (beforeFunc) beforeFunc;
    }), switchMap(() => source), tap(() => {
      if (afterFunc) afterFunc;
    }));
  };
}

/**
 * This operator helps you to debug your Observables.
 *
 * The ⏩ console logging statements in your browser are "running" observables (next)
 *
 * The ✅ ones are "completed" observables
 *
 * The ⛔ ones are the "error" observables
 *
 * You can optionally provide the nextAndErrorOnly flag which will skip complete logging.
 * This is useful if you want to debug an observable pipe at multiple stages.
 *
 * @param {string} tag An optional tag to be printed with the logging statements
 * @param {boolean} nextAndErrorOnly An optional flag to skip complete logging
 * @param {boolean} disableTableLogging Optionally disable table logging for arrays
 * @return {Observable<T>} An observable that logs the values that are emitted by the source observable
 */
function debug(tag, nextAndErrorOnly, disableTableLogging) {
  const _logPreNext = `⏩ [Observable] [${tag}] `;
  const _logPreError = `⛔ [Observable] [${tag}] `;
  const _logPreComplete = `✅ [Observable] [${tag}] `;
  return function (source) {
    return source.pipe(tap({
      next(value) {
        if (Array.isArray(value) && !disableTableLogging) {
          console.log(_logPreNext, 'Array as table logging:');
          console.table(value);
        } else console.log(_logPreNext, value);
      },
      error(error) {
        console.log(_logPreError, error);
      },
      complete() {
        if (nextAndErrorOnly) return;
        console.log(_logPreComplete);
      }
    }));
  };
}

/**
 * This operator uses an array of observable which are emitting arrays.
 * These getting merged into a single observable which emits arrays.
 * @param {Function} sortFunc accepts optionally a function which sorts the emitting array
 * @return {Observable} An observable which emits arrays
 */
function mergeArrays(sortFunc) {
  return function (source) {
    return source.pipe(map(arrays => {
      if (!Array.isArray(arrays)) return arrays;
      let merged = [];
      for (let array of arrays) {
        merged = [...merged, ...array];
      }
      if (sortFunc) merged = merged.sort((a, b) => sortFunc(a, b));
      return merged;
    }));
  };
}

/**
 * @ignore Internal: Don't be shown in documentation
 */
function inputIsNotNullOrUndefined(input) {
  return input !== null && input !== undefined;
}
/** This operator allows you to filter values of a stream by type.
 * It is useful for filtering out null or undefined values.
 * e.g. by using this.myObservable.pipe(notNullOrUndefined())
 */
function isNotNullOrUndefined() {
  return source$ => source$.pipe(filter(inputIsNotNullOrUndefined));
}

/**
 * Generated bundle index. Do not edit.
 */

export { beforeAfter, debug, isNotNullOrUndefined, mergeArrays };
